import { formatDate, formatDate2, ResolutionTypeToText, getCustomerResolutionTypeText } from '../helpers';

import { BaseModel } from './base.model';

export class ClaimResolutionModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get claim() {
    return this._data.claim;
  }

  get relatedClaimItemId() {
    return this._data.relatedClaimItemId;
  }

  get product() {
    if (this.subject === 'ITEM') {
      const claimItem = this.claim.claimItems.find((claimItem: any) => claimItem.id === this._data.relatedClaimItemId);
      const item = this.order.products.find(
        (orderItem: any) => orderItem.sourceVariantId === claimItem.sourceVariantId,
      );

      if (item !== null) {
        return {
          currency: this.order.currency,
          description: item.variation,
          photo: item.photo,
          priceBeforeDiscounts: item.priceInDollars,
          priceInDollars: item.priceInDollars,
          quantity: this._data.quantity,
          title: item.productName,
        };
      }
    }

    return null;
  }

  get order() {
    return this.claim.order;
  }

  get subject() {
    return this._data.resolutionSubject;
  }

  get type() {
    return ResolutionTypeToText[this._data.resolutionType];
  }

  get typeForCustomer() {
    return getCustomerResolutionTypeText(this._data.resolutionType);
  }

  get details() {
    return this._data.resolutionDetails;
  }

  get user() {
    return this._data.user;
  }

  get providedResolution() {
    return this._data.resolutionType;
  }

  get isCreditStore() {
    return this.type === 'CREDIT';
  }

  get isRefundShipping() {
    return this.subject === 'SHIPPING';
  }

  get isReplacementOrder() {
    return this.subject === 'REPLACEMENT';
  }

  get lineItems() {
    return [this.item];
  }

  get quantity() {
    return this._data.quantity;
  }

  get refundedQuantity() {
    return this._data.refundedQuantity;
  }

  get validQuantity() {
    const quantity = this.orderItem.quantity;
    const refundedQuantity = this.refundedQuantity;
    const claimQuantity = this.quantity;
    const remainingQuantity = quantity - refundedQuantity;
    if (remainingQuantity === 0 || claimQuantity > remainingQuantity) {
      return remainingQuantity;
    }

    return claimQuantity;
  }

  get item() {
    if (this.subject === 'ITEM') {
      const item = this.order.orderItems.find((orderItem: any) => orderItem.id === this._data.resolutionReference);

      return item
        ? {
            description: '',
            photo: item.productImage,
            priceBeforeDiscounts: '',
            priceBeforeDiscountsNormalized: 0,
            pricePaid: this.order.currency + ' ' + item.price,
            pricePaidNormalized: item.price,
            quantity: this.quantity,
            tags: [
              {
                label: 'Damaged',
                type: 'danger',
              },
            ],
            title: item.productName,
          }
        : null;
    }

    return null;
  }

  get claimId() {
    return this.claim.id;
  }

  get paidValue() {
    return this._data.paidValue;
  }

  get shippingAmount() {
    return this._data.shippingAmount;
  }

  get creditStore() {
    return this._data.creditStore;
  }

  get paidOriginalValue() {
    return this._data.paidOriginalValue;
  }

  get paidOriginalCurrency() {
    return this._data.paidOriginalCurrency;
  }

  get totalPaidInOriginalCurrency() {
    const totalPaidInOriginalCurrency = this.paidOriginalValue * this._data.quantity;

    return totalPaidInOriginalCurrency.toFixed(2);
  }

  get totalPaidInDollars() {
    const totalPaidInDollars = this._data.paidValue * this._data.quantity;

    return totalPaidInDollars.toFixed(2);
  }

  get shippingClaim() {
    return this._data.resolutionType;
  }

  get filedOn() {
    return formatDate(this._data.createdAt);
  }

  get reimburse() {
    return this._data.creditStore;
  }

  get resolvedBy() {
    return this._data.resolvedBy;
  }

  get resolvedDate() {
    return formatDate(this._data.createdAt);
  }

  get resolvedDateV2() {
    return formatDate2(this._data.createdAt);
  }

  get initialClaim() {
    return this._data.resolutionDescription;
  }

  get requestedResolution() {
    return this._data.resolutionType;
  }

  get uploadedPhotos() {
    return [];
  }
}
