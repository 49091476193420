import {
  StateToText,
  ClaimTypeToText,
  formatPrice,
  formatDate,
  humanizeCount,
  ResolutionTypeToText,
  formatDate2,
} from '../helpers';

import { REFUND, RESHIP, CUSTOM, STORE_CREDIT } from '../constants/claims/claims.constants';

import { BaseModel } from './base.model';
import { ClaimItemModel } from './claim-item.model';
import { ClaimResolutionModel } from './claim-resolution.model';
import countBy from 'lodash/countBy';

export class ClaimModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get uploadedPhotos() {
    return this._data.claimItems.flatMap((item: any) => item.images);
  }

  get userId() {
    return this._data.userId;
  }

  get printableId() {
    return this._data.sourceOrderNumber;
  }

  get order() {
    return this._data.order;
  }

  get sourceOrderId() {
    return this._data.sourceOrderId;
  }

  get sourceOrderNumber() {
    return this._data.sourceOrderNumber;
  }

  get orderId() {
    return this._data.orderId;
  }

  get stateText() {
    return StateToText[this._data.claimState] || 'Unknown';
  }

  get state() {
    return this._data.claimState?.toLowerCase();
  }

  get rawState() {
    return this._data.claimState;
  }

  get isResolvable() {
    return ['new', 'open', 'dismissed', 'pending'].includes(this.state);
  }

  get stateBadge() {
    switch (this.state) {
      case 'new':
        return 'info';
      case 'pending':
        return 'warning';
      case 'approved':
        return 'success';
      case 'denied':
        return 'danger';
      case 'dismissed':
        return 'neutral';
      case 'closed':
        return 'neutral';
      default:
        return 'info';
    }
  }

  get protectionStatus() {
    if (this.isOta) return 'ota';

    if (this._data.policy.removedAt) return 'removed';

    return this._data.policy !== null ? 'protected' : 'unprotected';
  }

  get isOta() {
    return this._data.isOta;
  }

  get author() {
    return this._data.authorName;
  }

  get customer() {
    return this.author;
  }

  get authorEmail() {
    return this._data.authorEmail;
  }

  get store() {
    return this._data.storeId || "Leo's";
  }

  get total() {
    return formatPrice(this._data.policy?.insuredAmount || 0, this._data.policy?.premiumCurrency || 'USD');
  }

  get description() {
    return this._data.claimDescription;
  }

  get protectionCost() {
    return formatPrice(this._data.policy?.premiumPaid || 0, this._data.policy?.premiumCurrency || 'USD');
  }

  get date() {
    return formatDate(this._data.createdAt);
  }

  get dateV2() {
    return formatDate2(this._data.createdAt);
  }

  get type() {
    return 'shipping_claim';
  }

  get storeCredits() {
    return this._data.claimStoreCredits;
  }

  set storeCredits(storeCredit) {
    this._data.claimStoreCredits = storeCredit;
  }

  get resolutionType() {
    return this.resolutions[0].type;
  }

  get resolutionTypeForCustomer() {
    return this.resolutions[0].typeForCustomer;
  }

  get claimType() {
    return ClaimTypeToText[this._data.claimType];
  }

  get rawClaimType() {
    return this._data.claimType;
  }

  set rawClaimType(claimType) {
    this._data.claimType = claimType;
  }

  get authorName() {
    return this._data.authorName;
  }

  get denialReason() {
    return ResolutionTypeToText[this._data.claimResolutions[0]?.resolutionType];
  }

  get claimStateBadge() {
    const typeMap = {
      approved: 'success',
      closed: 'neutral',
      denied: 'danger',
      dismissed: 'neutral',
      fulfilled: 'neutral',
      open: 'primary',
      payment: 'neutral',
      unfulfilled: 'neutral',
    } as any;

    return typeMap[this.state.toLowerCase()] || 'neutral';
  }

  get claimTypeBadge() {
    return 'danger';
  }

  get url() {
    return `/claims/view/${this.orderId}`;
  }

  get resolutions() {
    return ClaimResolutionModel.fromArrayExtending(this._data.claimResolutions, {
      claim: this,
    });
  }

  get resolution() {
    return this.resolutions[0];
  }

  get resolutionRequested() {
    const resolutions = countBy(this.claimItems, 'resolutionRequested');

    if (Object.keys(resolutions).length > 1) {
      return CUSTOM;
    } else if (resolutions[REFUND] > 0) {
      return REFUND;
    } else if (resolutions[RESHIP] > 0) {
      return RESHIP;
    }

    return STORE_CREDIT;
  }

  get providedResolution() {
    return this.resolutions[0]?.providedResolution;
  }

  get creditStore() {
    return this.resolutions.find((r: any) => r.isCreditStore);
  }

  get refundShipping() {
    return this.resolutions.find((r: any) => r.isRefundShipping);
  }

  get replacementOrder() {
    return this.resolutions.find((r: any) => r.isReplacementOrder);
  }

  get details() {
    return {
      lineItems: [],
    };
  }

  get subtotal() {
    return this.subtotalInUSD.toFixed(2);
  }

  get currency() {
    return this.order.currency;
  }

  get shippingCost() {
    return this.order.shippingCost;
  }
  get shippingCostTax() {
    return this.order.shippingCostTax;
  }

  get shippingCostInDollars() {
    return this.order.shippingCostInDollars;
  }
  get shippingTaxInDollars() {
    return this.order.shippingTaxInDollars;
  }

  get subtotalInUSD() {
    return this.claimItems.reduce(
      (total, item) => total + parseFloat((item.price - item.discount) * item.validQuantity * exchangeRate),
      0,
    );
  }

  get totalValue() {
    const lineItemsSum = this.claimItems.reduce((total, item) => {
      return total + parseFloat(item.price) * item.quantity; // Multiply the amount by the quantity
    }, 0);

    const taxesAmount = parseFloat(this.order.tax);
    const shippingAmount = parseFloat(this.order.shippingCost);
    const totalAmount = lineItemsSum + taxesAmount + shippingAmount;
    const totalAmountInUsd = totalAmount * exchangeRate;

    return totalAmountInUsd.toFixed(2);
  }

  get claimItems() {
    return ClaimItemModel.fromArrayExtending(this._data.claimItems, {
      claim: this,
    }) as ClaimItemModel[];
  }

  get messages() {
    return this._data.claimMessages;
  }

  get creditStoreTotal() {
    // We credit the store the value for the items + quantity
    return this.claimItems.reduce((total, item) => {
      return total + parseFloat(item.price) * item.quantity; // Multiply the amount by the quantity
    }, 0);
  }

  get creditStoreTotalInDollars() {
    const totalFromItems = this.claimItems.reduce((total, item) => {
      return total + parseFloat(item.price) * item.quantity; // Multiply the amount by the quantity
    }, 0);

    const totalFromItemsInDollars = totalFromItems * exchangeRate;

    return totalFromItemsInDollars.toFixed(2);
  }

  get itemsCount() {
    return this._data.claimItems?.length || 0;
  }

  get exchangeRate() {
    return this.order.exchangeRate;
  }

  get itemsCountHumanized() {
    return humanizeCount(this.itemsCount, 'item', 'items');
  }

  set messages(messages) {
    this._data.claimMessages = messages;
  }
}
